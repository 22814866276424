import React, {Component} from 'react'
import scrollToComponent from 'react-scroll-to-component';
//import BackButton from '../Controls/Back/BackButton'

class SellInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //redirect: false
        };

        //this.goBack = this.goBack.bind(this);
        this.scrollToInfo = this.scrollToInfo.bind(this);
    }

    componentDidMount() {
        this.scrollToInfo();
    }

    scrollToInfo() {
        scrollToComponent(this.info, { offset: 400, align: 'middle', duration: 500, ease:'inCirc'});
    }

    // goBack() {
    //     if(!this.props.back) {
    //         alert("Funkce pro historii není definovaná!!!");
    //         return;
    //     }
    //
    //     this.props.back();
    // }

    render() {
        return (
            <div>
                <section ref={(section) => { this.info = section; }}></section>
                <div className="prod-row" >
                    {/*<BackButton history={this.props.history} back={this.goBack}/>*/}
                    <div className="container">
                        <div className="module_header">
                            <div className=" module_title"><h3 className="header3">Prodej čerpadel</h3></div>
                        </div>
                        <p>Informace připravujeme ...</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default SellInfo