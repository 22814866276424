import React, {Component} from 'react';
//import * as Messages from '../../messages.js'
import './Header.css';

class Header extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <a className="header" onClick={this.props.showHome} title="domů">
                <header className="animated">
                    <div className="logo">
                        <img className="imgHeader" alt="" src="./assets/images/logo.png"/>
                    </div>
                </header>
            </a>
        );
    }
}

export default Header;
