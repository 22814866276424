import React, {Component} from 'react';
import {Form, Field} from 'simple-react-forms';
import {Toaster, Intent} from '@blueprintjs/core'
import axios from 'axios'
//styles
import './ContactForm.css'
import scrollToComponent from "react-scroll-to-component";

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.onClickHandler = this.onClickHandler.bind(this);
        this.isEmailCorrect = this.isEmailCorrect.bind(this);
        this.scrollToInfo = this.scrollToInfo.bind(this);

        this.state = {
            validationErrors: {},
            gdpr: false
        }
    }

    componentDidMount() {
        this.scrollToInfo();
    }

    scrollToInfo() {
        scrollToComponent(this.info, { offset: 400, align: 'middle', duration: 500, ease:'inCirc'});
    }

    handleCheckCondition(event) {
        this.setState({gdpr: event.target.checked});
    }

    isEmailCorrect(email) {
        if (email === undefined) return true;
        if (email.indexOf('@') === -1) return false;
        let id = email.indexOf('@');
        let res = email.substring(id);

        if (res.indexOf('.') === -1) return false;

        return true;
    }

    onClickHandler() {
        let data = this.refs['simpleForm'].getFormValues();
        if (!this.isEmailCorrect(data.email)) {
            this.toaster.show({intent: Intent.DANGER, message: "emailova adresa je ve špatném formátu nebo chybí!!!"});
            return;
        }

        if (data.name === "") {
            this.toaster.show({intent: Intent.DANGER, message: "není vyplněno jméno!!!"});
            return;
        }

        if (data.message === "") {
            this.toaster.show({intent: Intent.DANGER, message: "chybí zpráva!!!"});
            return;
        }
        //send email

        let from = data.email;
        let to = "cerpadl.ul@seznam.cz";//
        let name = data.name;
        let message = data.message;

        axios.post(
            '../../php/contacts.php', {
                data: {
                    'to': to,
                    'from': from,
                    'message': message,
                    'name': name
                }
            })
            .then(response => {
                // console.log(response);
                // //console.log(response.data);
                // //this.filter = response.data;
                this.toaster.show({intent: Intent.SUCCESS, message: "email byl úspěšně odeslán"});
                this.props.onClose();
            })
            .catch(e => {
                console.log(e);
                this.toaster.show({intent: Intent.DANGER, message: "email se z nějakého důvodu nepodařilo odeslat"});
            });

    }

    render() {
        return (
            <div className="margin-top">
                <section ref={(section) => { this.info = section; }}></section>
                <Toaster ref={(element) => {
                    this.toaster = element
                }}/>
                {/*<p className="contact-header">Kontaktní formulář</p>*/}
                <Form ref='simpleForm'>
                    <Field
                        name='name'
                        label='Jméno'
                        type='text'
                        placeholder='vaše jméno...'
                    />
                    <Field
                        name='email'
                        label='E-mailová adresa'
                        type='text'
                        placeholder='vaše e-mailová adresa, abychom vás mohli kontaktovat...'
                        required
                    />
                    <Field
                        style={{
                            resize: 'none',
                            minHeight: '165px'
                        }}
                        name='message'
                        label='Text zprávy'
                        type='textarea'
                        placeholder='text vaší zprávy...'
                        required
                    />
                </Form>
                <label className="pt-label contact">
                    <input type="checkbox" className="s-checkbox pt-checkbox"
                           onChange={this.handleCheckCondition.bind(this)}></input>
                    Souhlas se zpracováním osobních údajů
                </label>

                <button className="contactFormBtn margin-top" disabled={!this.state.gdpr}
                        onClick={this.onClickHandler.bind(this)}>Poslat
                </button>
            </div>
        );
    }


}

export default ContactForm;