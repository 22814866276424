import React, {Component} from 'react';
//import * as Messages from '../../messages.js'

import './Menu.css';

class MyMenu extends Component {
    constructor(props) {
        super(props);

        this.showSettings = this.showSettings.bind(this);

        this.state = {
            burgerMenuOpenned: false,
        };
    }

    showSettings(evt) {
        evt.preventDefault();
        this.setState({burgerMenuOpenned: !this.state.burgerMenuOpenned});
    }

    render() {
        let burgeMenuOpenned = this.state.burgerMenuOpenned;
        let burgerMenuStyle = burgeMenuOpenned ? {overflow: 'hidden', display: 'block'} : {
            overflow: 'hidden',
            display: 'none'
        };
        return (
            <div className="myMenu">
                <div className="navigation" style={{position: "relative", top: "0px"}}>
                    <div className="slicknav_menu">
                        <a onClick={ this.showSettings } href="" aria-haspopup="true"
                           className={burgeMenuOpenned ? 'slicknav_btn slicknav_open' : 'slicknav_btn slicknav_colapsed'}
                           style={{outline: "none"}}>
                            <span className="slicknav_menutxt">Navigace: </span>
                            <span className="slicknav_icon">
                                <span className="slicknav_icon-bar"></span>
                                <span className="slicknav_icon-bar"></span>
                                <span className="slicknav_icon-bar"></span>
                            </span>
                        </a>
                        <ul className={burgeMenuOpenned ? 'slicknav_nav slicknav_open' : 'slicknav_nav slicknav_colapsed'}
                            aria-hidden={burgeMenuOpenned ? 'false' : 'true'} role="menu"
                            style={burgerMenuStyle}>
                            <li className="item-menu"><a onClick={this.props.showHome} role="menuitem">Úvod</a></li>
                            <li className="item-menu"><a onClick={this.props.showServiceInfo} role="menuitem">Servis</a></li>
                            {/*<li className="item-menu"><a onClick={this.props.showPumpInfo} role="menuitem">Čerpadla</a></li>*/}
                            <li className="item-menu"><a onClick={this.props.showSellInfo}  role="menuitem">Prodej</a></li>
                            <li className="item-menu"><a onClick={this.props.showPriceList} role="menuitem">Ceník</a></li>
                            <li className="item-menu"><a onClick={this.props.scrollToContacts} role="menuitem">Kontakty</a></li>
                        </ul>
                    </div>
                    {/*<div className="container">*/}
                    <div className="row">
                        <div className="col-sm-3 col-md-5 col-lg-5"></div>
                        <div className="col-sm-9 col-md-7 col-lg-7">
                            <div className="tvtma-megamnu">
                                <div className="tvtma-megamnu">
                                    <ul className="sf-menuW sf-js-enabled sf-arrows">
                                        <li className="item-menu"><a onClick={this.props.showHome}>Úvod</a></li>
                                        <li className="item-menu"><a onClick={this.props.showServiceInfo}>Servis</a></li>
                                        {/*<li className="item-menu"><a onClick={this.props.showPumpInfo}>Čerpadla</a></li>*/}
                                        <li className="item-menu"><a onClick={this.props.showSellInfo}>Prodej</a></li>
                                        <li className="item-menu"><a onClick={this.props.showPriceList}>Ceník</a></li>
                                        <li className="item-menu last-child"><a onClick={this.props.scrollToContacts}>Kontakty</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyMenu;
