import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Client from './components/Client/Client'

import './App.css';

class App extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {/*<Route exact={true} path="/admin" render={(props) => {*/}
                        {/*return <Admin {...props} />*/}
                    {/*}}/>*/}
                    <Route path="/" render={(props) => {
                        return <Client {...props} />
                    }}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
