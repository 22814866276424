import React, {Component} from 'react';
//import {keys} from '../../base'

//styles
import "../../styles/font-awesome.css"
import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <footer className="animated">
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-xs-6">
                                <h3>Kontakt</h3>
                                <p><i>čerpadla</i><a title="napište mi" className="envelope" onClick={this.props.openForm} /*href="mailto:lucie.horvatova@seznam.cz"*/><i className="fa fa-envelope"></i></a></p>
                                <p><b>Ladislav Katič</b></p>
                                <p><i className="fa fa-phone"></i><b> +420 737 821 708<br/></b></p>
                                <p><i className="fa fa-map-marker"></i><span className="envelope">náměstí Prokopa Velikého 11/6, 400 01, Ústí nad Labem - Předlice</span></p>
                                <p><i className="fa fa-envelope"></i><a className="envelope" onClick={this.props.openForm} /*href="mailto:lucie.horvatova@seznam.cz"*/>cerpadla.ul@seznam.cz</a></p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-6">
                                <h3>Otevírací doba</h3>
                                <div className="col-lg-6 col-md-6 col-xs-6"><b>Pondělí</b></div><div className="col-lg-6 col-md-6 col-xs-6">6:00 - 14:00</div>
                                <div className="col-lg-6 col-md-6 col-xs-6"><b>Úterý</b></div><div className="col-lg-6 col-md-6 col-xs-6">6:00 - 14:00</div>
                                <div className="col-lg-6 col-md-6 col-xs-6"><b>Středa</b></div><div className="col-lg-6 col-md-6 col-xs-6">6:00 - 14:00</div>
                                <div className="col-lg-6 col-md-6 col-xs-6"><b>Čtvrtek</b></div><div className="col-lg-6 col-md-6 col-xs-6">6:00 - 14:00</div>
                                <div className="col-lg-6 col-md-6 col-xs-6"><b>Pátek</b></div><div className="col-lg-6 col-md-6 col-xs-6">6:00 - 14:00</div>
                                <div className="col-lg-6 col-md-6 col-xs-6"><b>dále dle tel. dohody</b></div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright row">
                        <div className="col-xs-12">
                            <a href="mailto:katak3471@gmail.com">&copy; joska production</a>
                            <p className="back-to-top"><a className="top" onClick={this.props.scrollToTop} id="back-to-top"><i className="fa fa-angle-up"></i></a></p>
                        </div>
                    </div>
                </div>
            </footer>

        );
    }
}

export default Footer;
