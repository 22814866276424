import React, {Component} from 'react'
import scrollToComponent from 'react-scroll-to-component';
//import BackButton from '../Controls/Back/BackButton'

class HomeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //redirect: false
        };

        //this.goBack = this.goBack.bind(this);
        // this.scrollToInfo = this.scrollToInfo.bind(this);
    }

    // scrollToInfo() {
    //     scrollToComponent(this.info, { offset: 400, align: 'middle', duration: 500, ease:'inCirc'});
    // }

    // goBack() {
    //     if(!this.props.back) {
    //         alert("Funkce pro historii není definovaná!!!");
    //         return;
    //     }
    //
    //     this.props.back();
    // }

    render() {
        return (
            <div>
                {/*<section ref={(section) => { this.info = section; }}></section>*/}
                <div className="prod-row" >
                    {/*<BackButton history={this.props.history} back={this.goBack}/>*/}
                    <div className="container">
                        <p>Specializujeme se na prodej, servis a montáž čerpadel, kvalitní
                            čerpací techniky pro domácnost, zahradu i průmysl. Působíme především v Ústí nad Labem a
                            okolí, ale po předchozí domluvě za vámi rádi dorazíme kamkoliv jinam.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomeInfo