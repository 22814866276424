import React, {Component} from 'react';
import {Spinner} from '@blueprintjs/core';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MyMenu from '../Menu/Menu';

import ContactForm from '../Controls/ContactForm/ContactForm'
import PriceList from "../Items/PriceList";
import PumpInfo from "../Items/PumpInfo";
import HomeInfo from "../Items/HomeInfo";
import SellInfo from "../Items/SellInfo";
import ServiceInfo from "../Items/ServiceInfo";

import scrollToComponent from 'react-scroll-to-component';

//import ContactForm from '../Controls/ContactForm/ContactForm'
//import cookie from 'react-cookies'

//styles
import '../../styles/bootstrap.min.css';
import '../../styles/custom.css';
import '../../styles/services.css';

class Client extends Component {
    constructor() {
        super();

        this.scrollToContacts = this.scrollToContacts.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);

        this.showHome = this.showHome.bind(this);
        this.openForm = this.openForm.bind(this);
        this.showPriceList = this.showPriceList.bind(this);
        this.showSellInfo = this.showSellInfo.bind(this);
        this.showPumpInfo = this.showPumpInfo.bind(this);
        this.showServiceInfo = this.showServiceInfo.bind(this);

        this.state = {
            loading: true,
            showHome: false,
            showPumpInfo: false,
            showServiceInfo: false,
            showSellInfo: false,
            visibleForm: false
        };

    }

    componentDidMount() {
        this.getData();
        this.setState({
            //visibleCookieBar: !cookie.load('accepts-cookies')
        });
    }

    showHome() {
        let _history = this.state.history;
        //_history.push("showHome");
        this.setState({
                showHome: true,
                visibleForm: false,
                // history: _history,
                showPumpInfo: false,
                showServiceInfo: false,
                showSellInfo: false,
                showPricelist: false,
                loading: false
            }
        );
    }

    openForm() {
        let that = this;
        this.setState({
            visibleForm: true,
            showHome: false,
            showPumpInfo: false,
            showServiceInfo: false,
            showSellInfo: false
            // }, () => {
            //     that.scrollToTop();
        });
    }


    showPriceList() {
        //let _history = this.state.history;
        // _history.push("showPricelist");
        this.setState({
            showHome: false,
            showPricelist: true,
            visibleForm: false,
            showPumpInfo: false,
            showServiceInfo: false,
            showSellInfo: false
        });
    }

    showSellInfo() {
        //let _history = this.state.history;
        // _history.push("showPricelist");
        this.setState({
            showHome: false,
            showPricelist: false,
            visibleForm: false,
            showPumpInfo: false,
            showServiceInfo: false,
            showSellInfo: true
        });
    }

    showServiceInfo() {
        //let _history = this.state.history;
        // _history.push("showPricelist");
        this.setState({
            showHome: false,
            showPricelist: false,
            visibleForm: false,
            showPumpInfo: false,
            showServiceInfo: true,
            showSellInfo: false
        });
    }

    showPumpInfo() {
        //let _history = this.state.history;
        // _history.push("showPricelist");
        this.setState({
            showHome: false,
            showPricelist: false,
            visibleForm: false,
            showPumpInfo: true,
            showServiceInfo: false,
            showSellInfo: false
        });
    }

    getData() {
        let that = this;
        that.showHome();
        //         }
        //     });
    }

    scrollToContacts(evt) {
        evt.preventDefault();
        scrollToComponent(this.contacts, {offset: 300, align: 'middle', duration: 500, ease: 'inCirc'});
    }

    scrollToTop() {
        scrollToComponent(this.top, {offset: 0, align: 'middle', duration: 500, ease: 'inCirc'});
    }

    render() {

        if (this.state.loading) {
            return (
                <div style={{textAlign: "center", position: "absolute", top: "25%", left: "50%"}}>
                    {/*<h3>Loading</h3>*/}
                    <Spinner/>
                </div>
            )
        }

        // let maskStyles = {
        //     borderRadius: "10px",
        //     backgroundColor: "#404040",
        //     marginTop: "15px",
        //     minHeight: "500px"
        // };

        return (
            <div id="pumpe">
                <section ref={(section) => {
                    this.top = section;
                }}></section>
                <Header
                    showHome={this.showHome}
                />
                <MyMenu
                    scrollToContacts={this.scrollToContacts}
                    showHome={this.showHome}
                    showSellInfo={this.showSellInfo}
                    showPumpInfo={this.showPumpInfo}
                    showServiceInfo={this.showServiceInfo}
                    showPriceList={this.showPriceList}
                />
                <div className="img-area">
                    <div className="img-label">Oprava, revize a servis čerpadel a elektromotorů</div>
                    {/*<div className="img-phone"><i className="fa fa-phone"></i><b> +420 737 821 708<br/></b></div>*/}
                    {/*<div className="img-desc">Specializujeme se na servis, revize a opravy čerpadel a elektromotorů...</div>*/}
                    <img className="main-img" alt="" src="./assets/images/back.png"/>
                    {/*{this.state.showHome ?*/}
                    {/*<HomeInfo/> :*/}
                    {/*<div/>*/}
                    {/*}*/}
                </div>
                {this.state.showPricelist ?
                    <PriceList/> :
                    <div/>
                }
                {this.state.showPumpInfo ?
                    <PumpInfo/> :
                    <div/>
                }
                {this.state.showSellInfo ?
                    <SellInfo/> :
                    <div/>
                }
                {this.state.showServiceInfo ?
                    <ServiceInfo/> :
                    <div/>
                }
                {this.state.visibleForm ?
                    <div className="contact-form">
                        <ContactForm/>
                    </div> :
                    <div/>
                }
                <section className='contacts' ref={(section) => {
                    this.contacts = section;
                }}></section>
                <Footer
                    openForm={this.openForm}
                    scrollToTop={this.scrollToTop}/>
            </div>
        );
    }
}

export default Client;
